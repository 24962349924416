import { Text, UserContainer } from '../../styles';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { WorkspaceService } from '../../services';
import { useStateValue } from '../../contexts';
import OpenNotification from '../OpenNotification';
import { IWorkspaceUser } from '../../types/IWorkspace';
const { Option } = Select;

interface Props {
  adminId: string;
  handleChange: (userId: string) => void;
}

const UserReservation = ({ adminId, handleChange }: Props) => {
  const [userSpinner, setUserSpinner] = useState(false);
  const [workspaceUsers, setWorkspaceUsers] = useState<IWorkspaceUser[]>([]);
  const [state] = useStateValue();
  const { workspace } = state;
  const workspaceService = new WorkspaceService();

  useEffect(() => {
    const getWorkspaceUser = async () => {
      setUserSpinner(true);
      const usersResponse = await workspaceService.getWorkspaceUsers(workspace.id);
      if (usersResponse.success) {
        setWorkspaceUsers(usersResponse.data);
      } else {
        OpenNotification({ type: 'error', message: usersResponse.message });
      }
      setUserSpinner(false);
    };

    if (workspace?.id) {
      getWorkspaceUser();
    }
  }, [workspace.id]);

  return (
    <UserContainer>
      <Text align="center">{`¿A que usuario desea reservar?`}</Text>
      <Select defaultValue={adminId} style={{ width: 220 }} onChange={handleChange} loading={userSpinner}>
        <Option value={adminId}>Yo</Option>
        {workspaceUsers.map((user: IWorkspaceUser) => {
          if (user.user.id !== adminId) {
            return (
              <Option key={user.user.id} value={user.user.id}>
                {user.profile.name || user.user.username || user.user.email}
              </Option>
            );
          }
        })}
      </Select>
    </UserContainer>
  );
};

export default UserReservation;
