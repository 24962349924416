import Profile from '@entio/accounts-sdk/dist/types/Profile';
import User from '@entio/accounts-sdk/dist/types/User';
import { Action } from '../contexts/StateContext';
import constants from './constant';

export interface SessionState {
  token: string;
  loggedIn: boolean;
  complexId: string;
  user: User;
  profile: Profile;
  userId: string;
}

const getItem = (key: string) => localStorage.getItem(key) || '';

export const INITIAL_SESSION_STATE = (): SessionState => {
  const token = getItem('token');
  const complexId = getItem('complexId');
  const user = getItem('currentUser');
  const profile = getItem('currentProfile');
  const userId = getItem('userId');
  return {
    token,
    loggedIn: !!token,
    complexId: complexId,
    user: JSON.parse(user || '{}'),
    profile: JSON.parse(profile || '{}'),
    userId: userId,
  };
};

export const authReducer = (state: SessionState, action: Action<SessionState>): SessionState => {
  switch (action.type) {
    case constants.LOGIN: {
      const { user, profile, token } = action.payload;
      if (!token || !user || !profile) {
        return { ...INITIAL_SESSION_STATE() };
      }
      const userId = user.id;
      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('currentProfile', JSON.stringify(profile));
      localStorage.setItem('userId', userId);
      localStorage.setItem('token', token);
      const complexId = getItem('complexId');
      return {
        token,
        loggedIn: !!token,
        complexId: complexId,
        user: user,
        profile: profile,
        userId: userId,
      };
    }

    case constants.LOGOUT: {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentProfile');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');

      const sessionInitialState = INITIAL_SESSION_STATE();
      return { ...sessionInitialState };
    }

    default:
      return state;
  }
};
