import styled from 'styled-components';
import { colors } from '../Colors';

export const ResourceItemContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  margin-bottom: 16px;
  flex-direction: column;
  align-content: center;
  border: 1px solid ${colors.lightgrey};
  border-radius: 10px;
  color: ${colors.grey};
  cursor: pointer;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
