import React from 'react';
import ReservationList from 'src/components/reservationList/ReservationList';
import TittleBar from 'src/components/tittleBar/TittleBar';
import { PageContainer } from '../styles/Basics';

const ReservationListPage = () => (
  <>
    <TittleBar pathBack={'/'} title="Reservaciones" />
    <PageContainer>
      <ReservationList />
    </PageContainer>
  </>
);

export default ReservationListPage;
