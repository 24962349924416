import styled from 'styled-components';
import { colors } from '../styles/Colors';

export const Title = styled.h4`
  text-align: center;
  padding: 0px;
  margin-bottom: 24px;
  font-size: 1.5em;
  font-weight: 600;
  color: ${colors.grey};
`;

export interface TextProps {
  align?: 'center' | 'end' | 'justify' | 'left' | 'right' | 'start';
}

export const Text = styled.p<TextProps>`
  padding: 0px 4px;
  margin: 8px 0px;
  font-weight: 400;
  font-size: 1em;
  color: ${colors.grey};
  text-align: ${(props) => props.align || 'left'};

  strong {
    font-weight: 600;
  }
`;

export const Legend = styled.p<TextProps>`
  font-size: 8pt;
  color: ${colors.grey};
  text-align: ${(props) => props.align || 'left'};
  margin: 0;
`;
