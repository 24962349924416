import React from 'react';
import { Row, Col } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';

import { ButtonSpace, ChecksContainer } from '../../styles/Basics';
import { Legend, Text } from '../../styles/TextStyle';
import listBlocks from '../../utils/listBlocks';
import { Block } from '../../types/NewReservation';
import { IBlock } from 'src/types/IBlocks';
import OpenNotification from '../OpenNotification';
interface IProp {
  time: [string, string];
  blockSize: number;
  blocks: Block[];
  blocksReservated: IBlock[];
  max_person?: number;
  max_reservation?: number;
  numPersons: number;
  date: Moment;
  onChangeBlock: (blocks: Block[]) => void;
}

const CheckboxReservation = ({
  blocks,
  time: [starttime, endtime],
  blockSize,
  blocksReservated,
  max_person,
  max_reservation,
  numPersons,
  date,
  onChangeBlock,
}: IProp) => {
  const getEndTime = (time: Moment) => time.clone().add(blockSize * 60, 'minutes');

  const isToday = moment().isSame(date, 'day');

  const getBlocks = listBlocks(starttime, endtime, blockSize, isToday);

  const listBlock: Block[] = getBlocks.map((time) => {
    const res: Block = {
      starttime: time.clone().format('HH:mm'),
      endtime: getEndTime(time).format('HH:mm'),
      count_reservations: 0,
      count_persons: 0,
    };
    const reservations = blocksReservated.find((block) => block.starttime === res.starttime)?.reservations;
    if (reservations) {
      const count_persons = reservations.reduce(
        (acc, reservation) => (reservation.num_person ? reservation.num_person + acc : acc),
        0
      );
      res.count_reservations = reservations.length;
      res.count_persons = count_persons;
    }
    return res;
  });

  const exist = (starttime: string) => {
    const index = blocks.findIndex((block) => block.starttime === starttime);
    return index > -1 ? index : false;
  };

  const isMaxReservation = (block: Block): boolean =>
    !!max_reservation && !!block.count_reservations && max_reservation <= block.count_reservations;

  const onSelect = (block: Block) => {
    const index = exist(block.starttime);
    if (index !== false) {
      // if exit block, delete of array
      onChangeBlock([...blocks].filter((_, i) => i !== index));
      return;
    }
    const limit = !!max_person || false;
    const space = (max_person || 0) - (block.count_persons || 0);
    if (limit && numPersons > space) {
      OpenNotification({ type: 'error', message: `El maximo de personas permitidas para este horario es de ${space}` });
      return;
    }
    if (isMaxReservation(block)) {
      OpenNotification({ type: 'error', message: `Solo se permiten ${max_reservation} para este horario` });
      return;
    }
    onChangeBlock([...blocks, block]);
  };

  const listBlockRender = () =>
    listBlock.map((block, i) => {
      const limit = !!max_person || false;
      const space = (max_person || 0) - (block.count_persons || 0);
      return (
        <Col xs={12} md={8} lg={6} key={i}>
          <ButtonSpace
            type={exist(block.starttime) !== false ? 'primary' : 'default'}
            disabled={(limit && space < 1) || isMaxReservation(block)}
            onClick={() => onSelect(block)}>
            {moment(block.starttime, 'HH:mm').format('h:mm a')} - {moment(block.endtime, 'HH:mm').format('h:mm a')}
          </ButtonSpace>
          {limit && space >= 1 && (
            <Legend align="left">
              <TeamOutlined />: {space}
            </Legend>
          )}
          {((limit && space < 1) || isMaxReservation(block)) && <Legend align="left">No disponible</Legend>}
        </Col>
      );
    });

  return (
    <ChecksContainer>
      <Text align="center">¿Qué horarios quisieras?</Text>
      <Row gutter={24} align="middle" justify="space-around">
        {listBlockRender()}
      </Row>
    </ChecksContainer>
  );
};

export default CheckboxReservation;
