import { Action } from 'src/contexts';
import { IWorkspace, TypeUser } from 'src/types';
import reducerConstants from './constant';

const storageData = localStorage.getItem('workspace') ? JSON.parse(localStorage.getItem('workspace') || '{}') : null;

export const INITIAL_WORKSPACE_STATE: IWorkspace = storageData || {
  id: '',
  name: '',
  permission: TypeUser.user,
  isAdmin: false,
};

export const workspaceReducer = (state: IWorkspace, action: Action<IWorkspace>): IWorkspace => {
  switch (action.type) {
    case reducerConstants.SELECT_WORKSPACE: {
      const isAdmin = action.payload?.permission === TypeUser.admin;
      return { ...(action.payload as IWorkspace), isAdmin };
    }
    default:
      return state;
  }
};
