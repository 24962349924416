import { ApiResponse } from '@entio/api-client';
import { BaseService } from './BaseService';
import { IResource } from '../types';
import { NewReservation } from 'src/types/NewReservation';
import { ReservationsList } from 'src/types/IReservation';

export class ReservationService extends BaseService {
  constructor(authorizationHeader?: string) {
    super(authorizationHeader);
  }

  workspaceId = localStorage.getItem('workspaceId');

  async createReservation(resourceId: string, newReservation: NewReservation): Promise<ApiResponse<unknown>> {
    const urlPath = `/workspace/${this.workspaceId}/resource/${resourceId}/reservation`;
    const url = this.getEndpointUrl(urlPath);

    return this.apiFetchWithSession<IResource[]>(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newReservation),
    });
  }

  async getReservation(resourceId: string, date?: string): Promise<ApiResponse<ReservationsList>> {
    const param = date ? `?date=${date}` : '';
    const urlPath = `/workspace/${this.workspaceId}/resource/${resourceId}/reservation${param}`;
    const url = this.getEndpointUrl(urlPath);

    return this.apiFetchWithSession<ReservationsList>(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
