import React from 'react';
import Profile from '@entio/accounts-sdk/dist/types/Profile';
import User from '@entio/accounts-sdk/dist/types/User';
import { Avatar } from 'antd';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  LeftOutlined,
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import moment from 'moment';

import { ReservationItemContainer } from '../../styles/adminStyles/reservationListStyle';
import { Block, IReservation } from 'src/types/IReservation';
import { useStateValue } from 'src/contexts';

interface IProp {
  reservation: IReservation;
  profile?: {
    user: User;
    profile: Profile;
  };
}

const ReservationItem = ({ reservation, profile }: IProp) => {
  console.log({ reservation, profile });
  const [{ workspace }] = useStateValue();
  const blocks: Block[] = reservation.blocks;

  const format = (time: string) => moment(time, 'HH:mm').format('h:mm a');

  const picture = profile?.profile.picture?.url ? { src: profile.profile.picture.url } : {};

  return (
    <ReservationItemContainer>
      {workspace.isAdmin && (
        <>
          <div className="avatar">
            <Avatar
              {...picture}
              /* src="https://lh3.googleusercontent.com/ogw/ADGmqu-xkGrfiEt1WrWgj1cQx8XxrJuTJNRVRyM13Q2zMPs=s83-c-mo" */
              style={{ backgroundColor: '#EA5A2E' }}
              size={64}
              icon={<UserOutlined />}
            />
          </div>
          <div className="info">
            <p>
              <strong>{profile?.profile?.name}</strong>
            </p>
            {profile?.user?.email && (
              <p>
                <MailOutlined /> {profile.user.email}
              </p>
            )}
            {profile?.user?.phone && (
              <p>
                <PhoneOutlined /> {profile.user.phone}
              </p>
            )}
          </div>
          <br />
        </>
      )}
      <div className="info">
        {reservation.date && (
          <p>
            <CalendarOutlined /> <strong>{moment(reservation.date).utc().format('dddd, DD [de] MMMM')}</strong>
          </p>
        )}
        <p>
          <TeamOutlined /> {reservation.num_person}
        </p>
        {blocks.map((block) => (
          <>
            <p style={{ color: '#0b8235' }}>
              <ClockCircleOutlined /> <RightOutlined /> {format(block.starttime)}
            </p>
            <p style={{ color: '#f81d22' }}>
              <ClockCircleOutlined /> <LeftOutlined /> {format(block.endtime)}
            </p>
          </>
        ))}
      </div>
    </ReservationItemContainer>
  );
};

export default ReservationItem;
