import Profile from '@entio/accounts-sdk/dist/types/Profile';
import User from '@entio/accounts-sdk/dist/types/User';

export enum TypeUser {
  user = 'USER',
  admin = 'ADMIN',
}

export interface IWorkspace {
  id: string;
  name: string;
  permission: TypeUser;
  isAdmin?: boolean;
}

export interface IWorkspaceUser {
  profile: Profile;
  user: User;
}
