import React from 'react';

import Workspace from '../components/workspace/Workspace';
import { PageContainer, Title } from '../styles';

const WorkspacePage = () => {
  console.log('workspace');
  return (
    <>
      <PageContainer>
        <Title>Tus sitios</Title>
        <Workspace />
      </PageContainer>
    </>
  );
};
export default WorkspacePage;
