import { ApiResponse } from '@entio/api-client';
import { BaseService } from './BaseService';
import { IResource } from '../types';
import { IBlock } from 'src/types/IBlocks';
export class ResourceService extends BaseService {
  constructor(authorizationHeader?: string) {
    super(authorizationHeader);
  }

  workspaceId = localStorage.getItem('workspaceId');

  async getResource(): Promise<ApiResponse<IResource[]>> {
    const urlPath = `/workspace/${this.workspaceId}/resource`;
    const url = this.getEndpointUrl(urlPath);

    return this.apiFetchWithSession<IResource[]>(url, { method: 'GET' });
  }

  async findResource(resourceId: string): Promise<ApiResponse<IResource>> {
    const urlPath = `/workspace/${this.workspaceId}/resource/${resourceId}`;
    const url = this.getEndpointUrl(urlPath);

    return this.apiFetchWithSession<IResource>(url, { method: 'GET' });
  }

  async getResourceBlocks(resourceId: string, date: string): Promise<ApiResponse<IBlock[]>> {
    const param = `?date=${date}`;
    const urlPath = `/workspace/${this.workspaceId}/resource/${resourceId}/blocks${param}`;
    const url = this.getEndpointUrl(urlPath);

    return this.apiFetchWithSession<IBlock[]>(url, { method: 'GET' });
  }
}
