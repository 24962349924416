import React from 'react';
import { Button, Col, Input, Row } from 'antd';
import { Text } from 'src/styles';

interface IProp {
  value: number;
  max?: number;
  onChange: (r: number) => void;
}

const CantPersons = ({ max, value, onChange }: IProp) => {
  const onCalculate = (value: number) => {
    if (value > 0 && (!max || value <= max)) {
      onChange(value);
    }
  };

  return (
    <>
      <Text align="center">Cantidad de personas que asistiran</Text>
      <Row justify="center" align="top">
        <Col md={1} sm={1}>
          <Button
            type="primary"
            block
            style={{ borderRadius: '5px 0px 0px 5px' }}
            onClick={() => onCalculate(value - 1)}>
            -
          </Button>
        </Col>
        <Col md={3} sm={12}>
          <Input
            type="number"
            min="1"
            max={max}
            defaultValue="1"
            style={{ textAlign: 'center' }}
            value={value}
            onChange={(e) => onChange(+e.target.value)}
          />
        </Col>
        <Col md={1} sm={1}>
          <Button
            type="primary"
            block
            style={{ borderRadius: '0px 5px 5px 0px' }}
            onClick={() => onCalculate(value + 1)}>
            +
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default CantPersons;
