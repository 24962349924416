import React from 'react';
import { useHistory } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';

import { ResourceItemContainer } from '../../styles/resourceStyles/ResourceListItemStyle';
import { Text } from '../../styles/TextStyle';
import { IResource } from 'src/types';
import { useStateValue } from 'src/contexts';

interface IProp {
  resource: IResource;
}

const ResourceListItem = ({ resource: { id, name, description } }: IProp) => {
  const [{ workspace }] = useStateValue();
  const history = useHistory();

  const open = (resourceId: string) => {
    history.push(`/resource/${resourceId}/list`);
  };

  return (
    <ResourceItemContainer onClick={() => open(id)}>
      <div>
        <Text>{name}</Text>
        {workspace.isAdmin && <MoreOutlined style={{ fontSize: '1.5em' }} />}
      </div>

      <Text>{description}</Text>
    </ResourceItemContainer>
  );
};

export default ResourceListItem;
