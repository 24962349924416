const reducerConstants = {
  LOGIN: 'USERS_LOGIN',
  LOGOUT: 'USERS_LOGOUT',

  SELECT_WORKSPACE: 'SELECT_WORKSPACE',

  SELECT_RESOURCE: 'SELECT_RESOURCE',
};

export default reducerConstants;
