import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from '../styles/Colors';

export const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  max-width: 850px;
  flex-direction: column;
  color: white;
  margin: 0 auto;

  .button {
    margin-top: 80px;
  }
`;

export const InputStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 24px 0px 24px;

  p {
    margin: auto 8px auto 8px;
    font-size: 1em;
    color: ${colors.grey};
  }

  div {
    min-width: 170px;
    width: 100%;
  }

  .checks {
    display: flex;
    justify-content: space-evenly;
  }
`;

export const AppContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 850px;
  flex-direction: column;
  align-content: center;
  height: inherit;
  overflow-y: scroll;
  padding: 0 5px;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* height */
  ::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.white};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.lightgrey};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.grey};
  }
`;

export const UserContainer = styled.div`
  margin: 0px auto 2px auto;
`;

export const CalendarContainer = styled.div`
  margin: 0px auto 24px auto;
`;

export const ChecksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 48px;
`;

export const ButtonSpace = styled(Button)`
  margin: 24px 0 0;
  width: 100%;
`;

export const ButtonConfirm = styled(Button)`
  position: fixed;
  bottom: 0;
  left: 0;
`;
