import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useStateValue } from 'src/contexts';
import { TypeUser } from 'src/types';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType;
}

const AdminRouter = ({ component: Component, ...rest }: IProps) => {
  const [{ workspace }] = useStateValue();

  const render = workspace.permission === TypeUser.admin ? <Component /> : <Redirect to={{ pathname: '/' }} />;

  return <Route {...rest} render={() => render} />;
};

export default AdminRouter;
