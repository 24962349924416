import { Action, IStateContext } from '../contexts/StateContext';
import { authReducer } from './authReducer';
import { resourceReducer } from './resourceReducer';
import { workspaceReducer } from './workspaceReducer';

const mainReducer = ({ session, resource, workspace }: IStateContext, action: Action<unknown>) => {
  return {
    session: authReducer(session, action),
    resource: resourceReducer(resource, action),
    workspace: workspaceReducer(workspace, action),
  };
};

export default mainReducer;
