import React from 'react';
import { PageContainer } from '../styles/Basics';
import Reservation from '../components/reservation/Reservation';
import TittleBar from '../components/tittleBar/TittleBar';

const ReservationPage = () => (
  <>
    <TittleBar pathBack={'/'} title="Nueva Reservación" />
    <PageContainer>
      <Reservation />
    </PageContainer>
  </>
);

export default ReservationPage;
