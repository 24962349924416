import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Moment } from 'moment';

import { ReservationService } from '../../services';
import { Block, NewReservation } from '../../types/NewReservation';
import OpenNotification from '../OpenNotification';
import { ButtonConfirm } from 'src/styles';

interface IProp {
  resource: string;
  date: Moment;
  blocks: Block[];
  numPersons: number;
  userReservation?: string;
}

const ButtonReservation = ({ resource, date, blocks, numPersons, userReservation }: IProp) => {
  const reservationService = new ReservationService();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const disabled = !date || !blocks.length;

  const reservation = async () => {
    const newReservation: NewReservation = { date: date.format('DD/MM/YYYY'), blocks, num_person: numPersons };
    if (userReservation) {
      newReservation.user = userReservation;
    }

    setLoading(true);
    const resp = await reservationService.createReservation(resource, newReservation);
    if (resp.success) {
      OpenNotification({
        type: 'success',
        message: resp.message,
        description: `Su reserva fue guardada con exito el ${date.format('dddd, DD [de] MMMM')}`,
      });
      history.push('/success');
    } else {
      OpenNotification({
        type: 'error',
        message: resp.message,
      });
    }
    setLoading(false);
  };

  return (
    <ButtonConfirm block type="primary" onClick={reservation} loading={loading} disabled={disabled}>
      RESERVAR
    </ButtonConfirm>
  );
};

export default ButtonReservation;
