import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';
import { AppContainer } from '../../styles/Basics';

interface IProp {
  title?: string;
  subTitle?: string;
}

const Success = ({ title, subTitle }: IProp) => {
  const history = useHistory();

  const goHome = () => history.push('/');

  return (
    <AppContainer>
      <Result
        status="success"
        title={title || '¡Listo!'}
        subTitle={subTitle || `¡Disfruta tu día!`}
        extra={[
          <Button type="primary" key="home" onClick={goHome}>
            Salir
          </Button>,
        ]}
      />
    </AppContainer>
  );
};

export default Success;
