import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Divider } from 'antd';
import moment, { Moment } from 'moment';
import { ReservationService, ResourceService } from '../../services';
import { IResource } from '../../types';
import Loading from '../Loading';
import OpenNotification from '../OpenNotification';
import CalendarReservation from './CalendarReservation';
import UserReservation from './UserReservation';
import CheckboxReservation from './CheckboxReservation';
import ButtonReservation from './ButtonReservation';
import CantPersons from './CantPersons';

import { Block } from '../../types/NewReservation';
import { Text, Title } from '../../styles';
import { IBlock } from '../../types/IBlocks';
import { useStateValue } from 'src/contexts';

interface MatchParams {
  resourceId: string;
}

const Reservation = () => {
  const reservationService = new ReservationService();
  const resourceService = new ResourceService();
  const [resource, setResource] = useState<IResource>();
  const [loading, setLoading] = useState(false);
  const [userReservation, setUserReservation] = useState('');
  const [date, setDate] = useState<Moment>(moment());
  const [block, setBlock] = useState<Block[]>([]);
  const [numPersons, setNumPersons] = useState(1);
  const [blocksReservated, setBlocksReservated] = useState<IBlock[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [state] = useStateValue();
  const {
    workspace,
    session: { userId },
  } = state;
  const { resourceId } = useRouteMatch<MatchParams>().params;

  const userReservationChange = async (userId: string) => {
    setUserReservation(userId);
  };

  useEffect(() => {
    const loadResource = async () => {
      setLoading(true);
      const resp = await resourceService.findResource(resourceId);
      if (resp.success) {
        setResource(resp.data);
      } else {
        OpenNotification({ type: 'error', message: resp.message });
      }
      setLoading(false);
      loadReservationOfUser();
    };

    const loadReservationOfUser = async () => {
      setLoading(true);
      const resp = await reservationService.getReservation(resourceId);
      if (resp.success) {
        console.log(resp.data);
        if (resp.data.reservations.length && !workspace.isAdmin) {
          setDisabled(true);
        }
      } else {
        OpenNotification({ type: 'error', message: resp.message });
      }
      setLoading(false);
    };

    if (resourceId) {
      loadResource();
    }
    return () => setDisabled(false);
  }, [resourceId]);

  useEffect(() => {
    const loadBlocksReservation = async () => {
      setLoading(true);
      const resp = await resourceService.getResourceBlocks(resourceId, date.format('DD/MM/YYYY'));
      if (resp.success) {
        setBlocksReservated(resp.data);
      } else {
        OpenNotification({ type: 'error', message: resp.message });
      }
      setLoading(false);
    };
    if (date && resourceId) {
      loadBlocksReservation();
    }
  }, [resourceId, date]);

  const onChangeDate = (d: Moment) => setDate(d);

  const onChangeBlock = (blocks: Block[]) => setBlock(blocks);

  if (!resource) {
    return loading ? <Loading /> : <Text align="center">No se encuentra información de esta area</Text>;
  }

  if (disabled) {
    return (
      <Text align="center">Tiene una reservación sin usar en esta area, luego de usarla podra volver a reservar</Text>
    );
  }

  return (
    <>
      <Title>{resource.name}</Title>
      <span>{resource.description}</span>
      <Divider />
      {workspace.isAdmin && (
        <>
          <UserReservation handleChange={userReservationChange} adminId={userId} />
          <Divider />
        </>
      )}
      <CalendarReservation date={date} onChange={onChangeDate} daysAvailable={resource.days_available} />
      <Divider />
      <CantPersons value={numPersons} onChange={setNumPersons} max={resource.max_persons} />
      <Divider />
      {loading ? (
        <Loading />
      ) : (
        <CheckboxReservation
          time={[resource.starttime, resource.endtime]}
          blockSize={resource.block_size}
          blocks={block}
          onChangeBlock={onChangeBlock}
          blocksReservated={blocksReservated}
          max_person={resource.max_persons}
          max_reservation={resource.max_reservation}
          numPersons={numPersons}
          date={date}
        />
      )}
      <ButtonReservation
        resource={resourceId}
        numPersons={numPersons}
        date={date}
        blocks={block}
        userReservation={userReservation}
      />
    </>
  );
};

export default Reservation;
