import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import { AppContainer, ButtonSpace } from 'src/styles';
import { IWorkspace } from 'src/types';
import { WorkspaceService } from 'src/services/WorkspaceService';
import OpenNotification from '../OpenNotification';
import Loading from '../Loading';
import { useHistory } from 'react-router-dom';
import { useStateValue } from 'src/contexts';
import { selectWorkspace } from 'src/actions/workspaceAction';

const { Option } = Select;

const Workspace = () => {
  const workspaceService = new WorkspaceService();

  const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
  const [{ workspace }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const loadWorkspace = async () => {
      setLoading(true);
      const resp = await workspaceService.getWorkspaces();
      if (resp.success) {
        setWorkspaces(resp.data);
      } else {
        OpenNotification({ type: 'error', message: resp.message });
      }
      setLoading(false);
      const workspaceId = localStorage.getItem('workspaceId');
      if (workspaceId) {
        saveWorkspace(workspaceId);
        return;
      }
      dispatch(selectWorkspace(resp.data[0]));
      if (resp.data.length === 1) {
        onSelect();
        return;
      }
    };
    loadWorkspace();
  }, []);

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    saveWorkspace(value);
  };

  const saveWorkspace = (workspaceId: string) => {
    const resp = workspaces.find(({ id }) => id === workspaceId);
    if (resp) {
      dispatch(selectWorkspace(resp));
    }
  };

  const onSelect = () => {
    if (!workspace) {
      return;
    }
    localStorage.setItem('workspaceId', workspace.id);
    localStorage.setItem('workspace', JSON.stringify(workspace));
    history.push('/');
  };

  const options = () =>
    workspaces.map(({ id, name }) => (
      <Option key={id} value={id}>
        {name}
      </Option>
    ));

  if (loading) {
    return (
      <AppContainer>
        <Loading />;
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <Row justify="center" align="middle">
        <Col span={4}>
          <Select value={workspace.id} placeholder="Elige un lugar" onChange={handleChange}>
            {options()}
          </Select>
          <ButtonSpace block type="primary" onClick={onSelect} disabled={!workspace.id}>
            SELECCIONAR
          </ButtonSpace>
        </Col>
      </Row>
    </AppContainer>
  );
};

export default Workspace;
