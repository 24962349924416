import React from 'react';
import { InputStyle } from 'src/styles/Basics';
import { Select } from 'antd';

const { Option } = Select;

const Hours = () => {
  return (
    <InputStyle>
      <p>Abierto desde:</p>
      <div>
        <Select defaultValue="7">
          <Option value="1">1:00 am</Option>
          <Option value="2">2:00 am</Option>
          <Option value="3">3:00 am</Option>
          <Option value="4">4:00 am</Option>
          <Option value="5">5:00 am</Option>
          <Option value="6">6:00 am</Option>
          <Option value="7">7:00 am</Option>
          <Option value="8">8:00 am</Option>
          <Option value="9">9:00 am</Option>
          <Option value="10">10:00 am</Option>
          <Option value="11">11:00 am</Option>
          <Option value="12">12:00 am</Option>
          <Option value="13">1:00 pm</Option>
          <Option value="14">2:00 pm</Option>
          <Option value="15">3:00 pm</Option>
          <Option value="16">4:00 pm</Option>
          <Option value="17">5:00 pm</Option>
          <Option value="18">6:00 pm</Option>
          <Option value="19">7:00 pm</Option>
          <Option value="20">8:00 pm</Option>
          <Option value="21">9:00 pm</Option>
          <Option value="22">10:00 pm</Option>
          <Option value="23">11:00 pm</Option>
          <Option value="0">00:00 am</Option>
        </Select>
      </div>
      <p>Hasta:</p>
      <div>
        <Select defaultValue="20">
          <Option value="1">1:00 am</Option>
          <Option value="2">2:00 am</Option>
          <Option value="3">3:00 am</Option>
          <Option value="4">4:00 am</Option>
          <Option value="5">5:00 am</Option>
          <Option value="6">6:00 am</Option>
          <Option value="7">7:00 am</Option>
          <Option value="8">8:00 am</Option>
          <Option value="9">9:00 am</Option>
          <Option value="10">10:00 am</Option>
          <Option value="11">11:00 am</Option>
          <Option value="12">12:00 am</Option>
          <Option value="13">1:00 pm</Option>
          <Option value="14">2:00 pm</Option>
          <Option value="15">3:00 pm</Option>
          <Option value="16">4:00 pm</Option>
          <Option value="17">5:00 pm</Option>
          <Option value="18">6:00 pm</Option>
          <Option value="19">7:00 pm</Option>
          <Option value="20">8:00 pm</Option>
          <Option value="21">9:00 pm</Option>
          <Option value="22">10:00 pm</Option>
          <Option value="23">11:00 pm</Option>
          <Option value="0">00:00 am</Option>
        </Select>
      </div>
    </InputStyle>
  );
};

export default Hours;
