import { IWorkspace } from './IWorkspace';

export enum WeekDays {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export type DaysAvailable = {
  [day in WeekDays]: boolean;
};

/**
 * TODO: Cambiar nombre resource
 */
export interface IResource {
  id: string;
  name: string;
  description: string;
  max_reservation: number;
  max_persons?: number;
  type?: string;
  days_available?: DaysAvailable;
  workspace: string | IWorkspace;
  starttime: string;
  endtime: string;
  /**
   * tamaño de bloque por hora ej: 1H = 1, 30m = 0.5
   */
  block_size: number;
}
