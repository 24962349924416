import React from 'react';
import { PageContainer } from '../styles/Basics';
import AvailableDay from 'src/components/admin/AvailableDay';
import Hours from 'src/components/admin/Hours';
import AreaName from 'src/components/admin/AreaName';
import { Button } from 'antd';
import TittleBar from 'src/components/tittleBar/TittleBar';

const AddArea = () => {
  return (
    <>
      <TittleBar pathBack={'/'} title="Agregar area" />
      <PageContainer>
        <AreaName />
        <AvailableDay />
        <Hours />
        <div className="button">
          <Button type="primary">AGREGAR AREA</Button>
        </div>
      </PageContainer>
    </>
  );
};

export default AddArea;
