import ApiClient from '@entio/api-client';

const DEFAULT_BASE_URL = 'http://localhost:3031/';
const { REACT_APP_RESERVAT_API_URL } = process.env;
const BASE_URL = REACT_APP_RESERVAT_API_URL || DEFAULT_BASE_URL;

export class BaseService extends ApiClient {
  constructor(authorizationHeader?: string) {
    super(authorizationHeader);
  }

  getEndpointUrl(urlPath: string) {
    // cleanse trailing slash to prevent duplicate slashes
    let baseUrl = BASE_URL;
    if (baseUrl && baseUrl.endsWith('/')) {
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    // cleanse leading slash to prevent duplicate slashes
    if (urlPath && urlPath.startsWith('/')) {
      urlPath = urlPath.substring(1);
    }

    // Safely join with slash after cleansing both segments
    return `${baseUrl}/${urlPath}`;
  }
}
