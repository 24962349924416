import React from 'react';
import { Calendar } from 'antd';
import CalendarLocale from 'antd/lib/locale/es_ES';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { DaysAvailable } from '../../types';
import moment, { Moment } from 'moment';

import { CalendarContainer } from '../../styles/Basics';
import { Text } from 'src/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const calendarLocale: any = CalendarLocale.Calendar;

const locale: PickerLocale = {
  lang: {
    placeholder: 'Seleccionar fecha',
    rangePlaceholder: ['Fecha inicial', 'Fecha final'],
    ...calendarLocale?.lang,
    year: '-',
  },
  timePickerLocale: {
    ...calendarLocale.timePickerLocale,
  },
};

interface IProp {
  date: Moment;
  onChange: (d: Moment) => void;
  daysAvailable?: DaysAvailable | undefined;
  title?: string;
}

const validRange: [Moment, Moment] = [moment().subtract(0, 'day'), moment().add(1, 'year')];

function disabledDate(current: Moment, daysDisabled: Array<number | never>): boolean {
  const validate = daysDisabled.find((day: number) => {
    return day === moment(current).day();
  });
  return (validate === 0 ? true : !!validate) || false;
}

const getDaysDisabled = (days_available: DaysAvailable | undefined) => {
  if (days_available) {
    const days = [];
    if (!days_available?.sunday) {
      days.push(0);
    }
    if (!days_available?.monday) {
      days.push(1);
    }
    if (!days_available?.tuesday) {
      days.push(2);
    }
    if (!days_available?.wednesday) {
      days.push(3);
    }
    if (!days_available?.thursday) {
      days.push(4);
    }
    if (!days_available?.friday) {
      days.push(5);
    }
    if (!days_available?.saturday) {
      days.push(6);
    }
    return days;
  } else {
    return [];
  }
};

const CalendarReservation = ({ date, onChange, daysAvailable, title }: IProp) => {
  const daysDisabled = getDaysDisabled(daysAvailable);
  return (
    <CalendarContainer>
      <Text align="center">{title || `¿Que fecha desea reservar?`}</Text>
      <Calendar
        mode="month"
        value={date}
        onSelect={onChange}
        fullscreen={false}
        validRange={validRange}
        locale={locale}
        disabledDate={(current: Moment) => disabledDate(current, daysDisabled)}
      />
    </CalendarContainer>
  );
};

export default CalendarReservation;
