import React from 'react';

import ResourceList from '../components/resource/ResourceList';
import TitleBar from '../components/tittleBar/TittleBar';
import { PageContainer } from '../styles/Basics';

const Resource = () => (
  <>
    <TitleBar title="Areas Comunes" />
    <PageContainer>
      <ResourceList />
    </PageContainer>
  </>
);

export default Resource;
