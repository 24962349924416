import React from 'react';
import { Input } from 'antd';
import { InputStyle } from 'src/styles/Basics';

const AreaName = () => {
  return (
    <InputStyle>
      <p>Nombre:</p>
      <Input placeholder="Nombre del Area" />
    </InputStyle>
  );
};

export default AreaName;
