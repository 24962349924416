import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import AdminRouter from './AdminRouter';
import AuthRouter from './AuthRouter';
import { adminRouter, authRouters, loginRouter } from './Routers';

const baseUrl = process.env.REACT_APP_BASE_PATH || '/';

const Router = () => (
  <BrowserRouter basename={baseUrl}>
    <Switch>
      {loginRouter.map((router, i) => (
        <Route key={i} exact {...router} />
      ))}
      {authRouters.map((router, i) => (
        <AuthRouter key={i} exact {...router} />
      ))}
      {adminRouter.map((router, i) => (
        <AdminRouter key={i} exact {...router} />
      ))}
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);

export default Router;
