import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useStateValue } from 'src/contexts';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType;
}

const AuthRouter = ({ component: Component, ...rest }: IProps) => {
  const [{ session }] = useStateValue();
  const workspaceId = localStorage.getItem('workspaceId') || rest.path === '/workspace';

  const render = !session.loggedIn ? (
    <Redirect to={{ pathname: '/login' }} />
  ) : !workspaceId ? (
    <Redirect to={{ pathname: '/workspace' }} />
  ) : (
    <Component />
  );

  return <Route {...rest} render={() => render} />;
};

export default AuthRouter;
