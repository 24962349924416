import { Action } from 'src/contexts';
import { IResource } from 'src/types';
import reducerConstants from './constant';

export const INITIAL_RESOURCE_STATE: IResource = {
  id: '',
  name: '',
  description: '',
  max_reservation: 1,
  max_persons: 1,
  workspace: '',
  starttime: '',
  endtime: '',
  block_size: 1,
};

export const resourceReducer = (state: IResource, action: Action<IResource>): IResource => {
  switch (action.type) {
    case reducerConstants.SELECT_RESOURCE: {
      return { ...(action.payload as IResource) };
    }
    default:
      return state;
  }
};
