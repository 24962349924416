import React, { ReactNode } from 'react';
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';

interface IProp {
  pathBack?: string;
  title?: string;
  subTitle?: string;
  extra?: ReactNode;
}

const TittleBar = ({ pathBack, ...rest }: IProp) => {
  const history = useHistory();

  const goBack = () => {
    if (pathBack) {
      history.push(pathBack);
    }
  };

  const back = pathBack ? { onBack: goBack } : {};

  return (
    <>
      <PageHeader {...back} {...rest} />
    </>
  );
};

export default TittleBar;
