import React from 'react';
import { InputStyle } from 'src/styles/Basics';
import { Checkbox } from 'antd';

const AvailableDay = () => {
  return (
    <InputStyle>
      <p>Días disponibles:</p>
      <div className="checks">
        <Checkbox>Lun</Checkbox>
        <Checkbox>Mar</Checkbox>
        <Checkbox>Mier</Checkbox>
        <Checkbox>Jue</Checkbox>
        <Checkbox>Vier</Checkbox>
        <Checkbox>Sab</Checkbox>
        <Checkbox>Dom</Checkbox>
      </div>
    </InputStyle>
  );
};

export default AvailableDay;
