import React from 'react';

import ReservationDetail from '../components/reservationdetails/ReservationDetail';
import TittleBar from '../components/tittleBar/TittleBar';
import { Title } from '../styles/TextStyle';
import { PageContainer } from '../styles/Basics';

const ReservationDetails = () => (
  <>
    <TittleBar pathBack={'/'} title="Reservación" />
    <PageContainer>
      <Title>Detalle de tu reservacion:</Title>
      <ReservationDetail />
    </PageContainer>
  </>
);

export default ReservationDetails;
