import React, { useEffect, useState } from 'react';

import { Skeleton } from 'antd';

import ResourceListItem from './ResourceListItem';
import { AppContainer } from '../../styles/Basics';
import { ResourceListContainer } from '../../styles/resourceStyles/ResourceListStyle';

import { IResource } from '../../types';
import { ResourceService } from '../../services';
import OpenNotification from '../OpenNotification';

const ResourceList = () => {
  const resourceService = new ResourceService();
  const [resources, setResources] = useState<IResource[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadResources = async () => {
      setLoading(true);
      const resp = await resourceService.getResource();
      if (resp.success) {
        setResources(resp.data);
      } else {
        OpenNotification({ type: 'error', message: resp.message });
      }
      setLoading(false);
    };
    loadResources();
  }, []);

  const ResourceList = () => resources.map((resource) => <ResourceListItem key={resource.id} resource={resource} />);

  return (
    <AppContainer>
      <ResourceListContainer>{loading ? <Skeleton active /> : ResourceList()}</ResourceListContainer>
    </AppContainer>
  );
};

export default ResourceList;
