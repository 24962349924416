import { ApiResponse } from '@entio/api-client';
import { BaseService } from './BaseService';
import { IWorkspace, IWorkspaceUser } from '../types';

export class WorkspaceService extends BaseService {
  constructor(authorizationHeader?: string) {
    super(authorizationHeader);
  }

  async getWorkspaces(): Promise<ApiResponse<IWorkspace[]>> {
    const urlPath = `/workspace/`;
    const url = this.getEndpointUrl(urlPath);

    return this.apiFetchWithSession<IWorkspace[]>(url, { method: 'GET' });
  }

  async getWorkspaceUsers(workspaceId: string): Promise<ApiResponse<IWorkspaceUser[]>> {
    const urlPath = `/workspace/${workspaceId}/users`;
    const url = this.getEndpointUrl(urlPath);

    return this.apiFetchWithSession<IWorkspaceUser[]>(url, { method: 'GET' });
  }
}
