import React from 'react';
import Success from '../components/success/Success';
import { PageContainer } from '../styles/Basics';

const SuccessPage = () => (
  <>
    <PageContainer>
      <Success />
    </PageContainer>
  </>
);

export default SuccessPage;
