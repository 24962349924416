import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { INITIAL_SESSION_STATE, SessionState } from '../reducers/authReducer';
import { INITIAL_RESOURCE_STATE } from '../reducers/resourceReducer';

import reducers from '../reducers';
import { IResource, IWorkspace } from 'src/types';
import { INITIAL_WORKSPACE_STATE } from 'src/reducers/workspaceReducer';

export interface IStateContext {
  session: SessionState;
  resource: IResource;
  workspace: IWorkspace;
}

export interface Action<P> {
  type: string;
  payload: Partial<P>;
}

export interface IContextProps extends Array<IStateContext | Dispatch<Action<unknown>>> {
  0: IStateContext;
  1: Dispatch<Action<unknown>>;
}

const sessionInitialState = INITIAL_SESSION_STATE();

const INITIAL_STATE = {
  session: { ...sessionInitialState },
  resource: { ...INITIAL_RESOURCE_STATE },
  workspace: { ...INITIAL_WORKSPACE_STATE },
};

export const StateContext = createContext<IContextProps>([INITIAL_STATE, () => ({})]);

const StateContextProvider = ({ children }: { children: JSX.IntrinsicAttributes }) => {
  const [state, dispatch] = useReducer(reducers, INITIAL_STATE);

  return <StateContext.Provider value={[state, dispatch]}>{children}</StateContext.Provider>;
};

export const useStateValue = () => useContext(StateContext);

export default StateContextProvider;
