import Profile from '@entio/accounts-sdk/dist/types/Profile';
import User from '@entio/accounts-sdk/dist/types/User';
import reducerConstants from '../reducers/constant';

interface Session {
  user: User;
  profile: Profile;
  token: string;
}

export const removeLocalStorageSession = () => {
  return {
    type: reducerConstants.LOGOUT,
  };
};

export const loginSession = (session: Session) => {
  return {
    type: reducerConstants.LOGIN,
    payload: session,
  };
};
