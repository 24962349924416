import React from 'react';
import { setOnValidateSession } from '@entio/accounts-react-sdk';

import moment from 'moment';
import 'moment/dist/locale/es';
import 'moment/min/locales';

import StateContextProvider, { useStateValue } from './contexts/StateContext';
import Router from './routers/Router';
import './App.css';

const App = () => {
  moment.locale('es');
  const [{ session }] = useStateValue();
  setOnValidateSession(() => session.loggedIn);
  console.log(process.env);
  return (
    <StateContextProvider>
      <div className="App">
        <Router />
      </div>
    </StateContextProvider>
  );
};

export default App;
