import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

interface IProp {
  resourceId: string;
}

const CreateReservationButton = ({ resourceId }: IProp) => {
  const history = useHistory();

  const go = () => history.push(`/resource/${resourceId}`);

  return (
    <>
      <Button type="primary" size="middle" onClick={go} style={{ marginBottom: 24 }}>
        Nueva Reservación
      </Button>
    </>
  );
};

export default CreateReservationButton;
