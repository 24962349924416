import styled from 'styled-components';
import { colors } from '../Colors';

export const ReservationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 48px;
`;

export const ReservationItemContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  margin-bottom: 16px;
  align-content: center;
  border: 1px solid ${colors.lightgrey};
  border-radius: 10px;
  color: ${colors.grey};

  .avatar {
    margin: 0 auto;
    height: 100%;
    width: 20%;
  }

  .info {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: 100%;
    width: 80%;
    text-align: left;

    p {
      margin: 0 0 0 24px;
    }
  }
`;
