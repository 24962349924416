import { notification } from 'antd';
import { IconType } from 'antd/lib/notification';

interface IProp {
  type: IconType;
  message: string;
  description?: string;
}

const OpenNotification = (data: IProp) => {
  notification.open(data);
};

export default OpenNotification;
