import React from 'react';
import { Affix, Button } from 'antd';

import { AppContainer } from '../../styles/Basics';
import { Text } from '../../styles/TextStyle';
import { ReservationContainer } from '../../styles/reservationStyles/ReservationDetailsStyle';

const ReservationDetail = () => {
  return (
    <AppContainer>
      <ReservationContainer>
        <Text>
          Area: <strong>Alberca</strong>
        </Text>
        <Text>
          Dia: <strong>15 Febrero</strong>
        </Text>
        <Text>
          Horario: <strong>6:00 am a 7:00 am</strong>
        </Text>
        <br />
        <Affix offsetBottom={10}>
          <Button type="primary">CONFIRMAR</Button>
        </Affix>
      </ReservationContainer>
    </AppContainer>
  );
};

export default ReservationDetail;
