import Login from '../pages/auth/Login';
import WorkspacePage from '../pages/Workspace';
import Resource from '../pages/Resource';
import Reservation from '../pages/Reservation';
import ReservationDetails from '../pages/ReservationDetails';
import Success from '../pages/Success';
import AddArea from '../pages/AddArea';
import ReservationListPage from '../pages/ReservationList';

export interface IRouter {
  path: string;
  name?: string;
  component: () => JSX.Element;
}

export const loginRouter: IRouter[] = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

export const authRouters: IRouter[] = [
  {
    path: '/workspace',
    name: 'WorkspacePage',
    component: WorkspacePage,
  },
  {
    path: '/',
    name: 'Resource',
    component: Resource,
  },
  {
    path: '/resource/:resourceId',
    name: 'Reservation',
    component: Reservation,
  },
  {
    path: '/details',
    name: 'ReservationDetails',
    component: ReservationDetails,
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
  },
  {
    path: '/resource/:resourceId/list',
    name: 'ReservationList',
    component: ReservationListPage,
  },
];

export const adminRouter: IRouter[] = [
  {
    path: '/add',
    name: 'AddArea',
    component: AddArea,
  },
];
const routers: IRouter[] = [...loginRouter, ...authRouters, ...adminRouter];

export default routers;
