import moment, { Moment } from 'moment';

/**
 *
 * @param starttime init hour resource, Format '12:00'
 * @param endtime end hour resource, Format '12:00'
 * @param blockSize hour(s) size
 */
const listBlocks = (starttime: string, endtime: string, blockSize: number, today = false) => {
  const blockMinutes = blockSize * 60;
  const item: Moment[] = [];
  const currentTime = moment(starttime, 'HH:mm');
  const endTime = moment(endtime, 'HH:mm');
  if (currentTime.diff(endTime, 'minutes') > 0) {
    endTime.add(1, 'day');
  }
  while (currentTime.diff(endTime, 'minutes') <= 0) {
    console.log(currentTime.diff(moment(), 'minutes'), today);
    if (!today || currentTime.diff(moment(), 'minutes') >= 0) {
      item.push(currentTime.clone());
    }
    currentTime.add(blockMinutes, 'minutes');
  }
  return item;
};

export default listBlocks;
