import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Col, Row, Switch } from 'antd';

import CalendarReservation from '../reservation/CalendarReservation';
import ReservationItem from './ReservationItem';
import Loading from '../Loading';
import { ReservationService, ResourceService } from 'src/services';
import OpenNotification from '../OpenNotification';
import { Text, Title } from 'src/styles';
import { Profiles } from 'src/types/IProfile';
import { IReservation } from 'src/types/IReservation';
import { IResource } from '../../types';
import CreateReservationButton from '../CreateReservationButton';

interface MatchParams {
  resourceId: string;
}

const ReservationList = () => {
  const resourceService = new ResourceService();
  const reservationService = new ReservationService();
  const [resource, setResource] = useState<IResource>();
  const [date, setDate] = useState<Moment>(moment());
  const [forDate, setForDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reservations, setReservations] = useState<IReservation[]>([]);
  const [profiles, setProfiles] = useState<Profiles>();

  const { resourceId } = useRouteMatch<MatchParams>().params;

  useEffect(() => {
    const loadResource = async () => {
      setLoading(true);
      const resp = await resourceService.findResource(resourceId);
      if (resp.success) {
        setResource(resp.data);
      } else {
        OpenNotification({ type: 'error', message: resp.message });
      }
      setLoading(false);
    };

    if (resourceId) {
      loadResource();
    }
  }, [resourceId]);

  useEffect(() => {
    const loadReservations = async () => {
      setLoading(true);
      const dateParam = forDate ? date.format('DD/MM/YYYY') : undefined;
      const resp = await reservationService.getReservation(resourceId, dateParam);
      if (resp.success) {
        setReservations(resp.data.reservations);
        setProfiles(resp.data.profiles);
      } else {
        OpenNotification({ type: 'error', message: resp.message });
      }
      setLoading(false);
    };

    if (resourceId) {
      loadReservations();
    }
  }, [resourceId, date, forDate]);

  const onChangeDate = (d: Moment) => setDate(d);

  const listReservations = () => {
    if (!reservations.length) {
      return <h4 style={{ textAlign: 'center', marginTop: 8 }}>Sin reservaciones {forDate && `para este dia`}</h4>;
    }
    return reservations.map((reservation, i) => (
      <ReservationItem key={i} reservation={reservation} profile={profiles && profiles[reservation.user]} />
    ));
  };

  if (loading && !resource) {
    return <Loading />;
  }

  return (
    <>
      <Row align="top" justify="space-around">
        <Col md={{ span: 12, offset: 6, order: 1 }} xs={{ span: 24, offset: 0, order: 2 }}>
          <Title>{resource?.name}</Title>
        </Col>
        <Col md={{ span: 6, offset: 0, order: 2 }} xs={{ span: 12, offset: 12, order: 1 }}>
          <CreateReservationButton resourceId={resourceId} />
        </Col>
      </Row>
      <Row align="middle" justify="start" onClick={() => setForDate((value) => !value)}>
        <Col>
          <Text>Filtrar por fecha</Text>
        </Col>
        <Col>
          <Switch checked={forDate} />
        </Col>
      </Row>
      {forDate && (
        <CalendarReservation
          title="Selecciona una fecha"
          date={date}
          onChange={onChangeDate}
          daysAvailable={resource?.days_available}
        />
      )}
      {loading ? <Loading /> : listReservations()}
    </>
  );
};

export default ReservationList;
